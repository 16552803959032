<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="90px">
        <el-form-item label="关键字" class="keywords">
          <el-input
            placeholder="请输入"
            v-model="searchData.keywordValue"
            class="input-with-select"
            size="small"
          >
            <el-select
              v-model="searchData.keywordName"
              slot="prepend"
              placeholder="请选择"
              size="small"
            >
              <el-option
                v-for="item in channelSearchKw"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item label="订单状态" class="item">
          <el-select
            v-model="searchData.orderStatusList"
            placeholder="请选择"
            size="small"
            multiple
            clearable
          >
            <el-option
              v-for="item in channelOrderStatus"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否在租" class="item">
          <el-select
            v-model="searchData.memberType"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in memberType"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否注册" class="item">
          <el-select
            v-model="searchData.isRegister"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in isRegister"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="下单时间" class="item">
          <el-date-picker
            style="width: 320px"
            v-model="searchData.timeSlot"
            size="small"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="支付时间" class="item">
          <el-date-picker
            style="width: 320px"
            v-model="searchData.payTimeSlot"
            size="small"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label=" ">
          <el-button
            type="primary"
            size="small"
            icon="el-icon-search"
            @click="getList"
            >搜索</el-button
          >
          <el-button
            type="info"
            size="small"
            icon="el-icon-refresh"
            @click="reset"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <!-- <div class="header-container">
      <div class="left">
        <el-form ref="form" inline :model="searchData" label-width="100px">
          <el-form-item label="关键字" class="keywords">
            <el-input placeholder="请输入" v-model="searchData.keywordValue" class="input-with-select" size="small">
              <el-select v-model="searchData.keywordName" slot="prepend" placeholder="请选择" size="small">
                <el-option v-for="item in channelSearchKw" :key="item.value" :label="item.name" :value="item.value"></el-option>
              </el-select>
            </el-input>
          </el-form-item>
          <el-form-item label="订单状态">
            <el-select v-model="model" placeholder="请选择" size="small">
              <el-option v-for="item in channelOrderStatus" :key="item.value" :label="item.name" :value="item.name"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否在租">
            <el-select v-model="model" placeholder="请选择" size="small">
              <el-option v-for="item in channelOrderStatus" :key="item.value" :label="item.name" :value="item.name"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否注册" v-show="isShow">
            <el-select v-model="model" placeholder="请选择" size="small">
              <el-option v-for="item in channelOrderStatus" :key="item.value" :label="item.name" :value="item.name"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="用户类型" v-show="isShow">
            <el-select v-model="model" placeholder="请选择" size="small">
              <el-option v-for="item in channelOrderStatus" :key="item.value" :label="item.name" :value="item.name"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="下单时间" v-show="isShow">
            <el-date-picker
              v-model="searchData.timeSlot"
              size="small"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
    
        </el-form>
      </div>
      <div class="right">
        <span class="open" @click="isShow = !isShow">{{ isShow ? '收起' : '展开' }}</span>
        <i class="updown" :class="['el-icon-arrow-up', isShow ? '' : 'go']"></i>
        <el-button style="margin-left:10px" @click="getList" type="primary" icon="el-icon-search" size="small">查询</el-button>
        <el-button @click="reset" size="small" icon="el-icon-refresh">重置</el-button>
      </div>
    </div> -->
    <el-divider></el-divider>
    <div class="handle-btn">
      <div></div>
      <el-button
        v-checkbtn="isShowBtn(AUTH_BTN.channel_order_export)"
        type="primary"
        icon="el-icon-download"
        size="small"
        @click="handleExport"
        >导出</el-button
      >
    </div>
    <tp-table
      :isNeedSerialNumber="true"
      :tableData="list"
      :columns="columns"
      :totalNum="total"
      :current-page.sync="currentPage"
      :pageSize.sync="pageSize"
    />
  </div>
</template>

<script>
import { DownloadAPI, getListAPI } from "./api";
import {
  channelSearchKw,
  channelOrderStatus,
  memberType,
  isRegister,
} from "@/enum/dict/index.js";
import { authBtnMixin } from "@/mixins/authBtnMixin.js";
const columns = [
  {
    label: "订单渠道",
    prop: "orderChannel",
    minWidth: "120",
    customRender(h, row) {
      return <p>{row["orderChannel"]?.["channelName"]}</p>;
    },
  },
  {
    label: "用户名",
    prop: "member",
    minWidth: "100",
    customRender(h, row) {
      return <p>{row["member"]?.["memberName"]}</p>;
    },
  },

  {
    label: "手机号",
    prop: "member",
    minWidth: "120",
    customRender(h, row) {
      return <p>{row["member"]?.["mobile"]}</p>;
    },
  },
  {
    label: "订单号",
    prop: "order",
    minWidth: "220",
    customRender(h, row) {
      return <p>{row["order"]?.["orderNo"]}</p>;
    },
  },

  {
    label: "商品名",
    prop: "order",
    minWidth: "180",
    customRender(h, row) {
      return <p>{row["order"]?.["orderGoods"]?.["skuTitle"]}</p>;
    },
  },
  {
    label: "总租金",
    prop: "order",
    minWidth: "120",
    customRender(h, row) {
      return <p>{row["order"]?.["rentPrice"]}</p>;
    },
  },
  {
    label: "订单状态",
    prop: "order",
    minWidth: "110",
    customRender(h, row) {
      return <p>{row["order"]?.["statusName"]}</p>;
    },
  },
  {
    label: "下单时间",
    prop: "orderTime",
    minWidth: "180",
    customRender(h, row) {
      return <p>{row["orderTime"]}</p>;
    },
  },
  {
    label: "支付时间",
    prop: "payTime",
    minWidth: "180",
    customRender(h, row) {
      return <p>{row["payTime"]}</p>;
    },
  },
  {
    label: "是否已注册",
    prop: "isRegister",
    minWidth: "110",
    customRender(h, row) {
      return (
        <p>
          <el-tag v-show={row["isRegister"] == 0} size="small" type="danger">
            新注册
          </el-tag>
          <el-tag v-show={row["isRegister"] == 1} size="small" type="success">
            已注册
          </el-tag>
        </p>
      );
    },
  },
  {
    label: "是否在租",
    prop: "memberType",
    minWidth: "100",
    customRender(h, row) {
      return (
        <p>
          <el-tag v-show={row["memberType"] == 1} size="small" type="success">
            新客
          </el-tag>
          <el-tag v-show={row["memberType"] == 2} size="small" type="primary">
            在租
          </el-tag>
        </p>
      );
    },
  },
  {
    label: "用户渠道",
    prop: "memberChannel",
    minWidth: "150",
    customRender(h, row) {
      return <p>{row["memberChannel"]?.["channelName"]}</p>;
    },
  },
];
export default {
  name: "List",
  mixins: [authBtnMixin],
  data() {
    return {
      isShow: false, //控制箭头
      dialogVisible: false,
      columns,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      list: [],
      searchData: {
        orderStatusList: [],
        isRegister: "",
        memberType: "",
        leftOrderDate: "",
        rightOrderDate: "",
        keywordValue: "",
        keywordName: "",
        timeSlot: [],
        payTimeSlot: [],
      },
      channelSearchKw,
      channelOrderStatus,
      memberType,
      isRegister,
    };
  },
  provide() {
    return {
      context: this,
    };
  },
  watch: {
    currentPage() {
      this.getList();
    },
    pageSize() {
      this.getList();
    },
  },
  mounted() {
    this.getList();
  },

  methods: {
    async getList() {
      let {
        timeSlot,
        payTimeSlot,
        keywordName,
        keywordValue,
        orderStatusList,
        isRegister,
        memberType,
      } = this.searchData;
      let params = {
        page: this.currentPage,
        pageSize: this.pageSize,
        orderStatusList: orderStatusList.join(),
        isRegister,
        memberType,
      };
      if (timeSlot && timeSlot.length > 0) {
        params.leftOrderDate = timeSlot[0];
        params.rightOrderDate = timeSlot[1];
      }
      if (payTimeSlot && payTimeSlot.length > 0) {
        params.leftPayDate = payTimeSlot[0];
        params.rightPayDate = payTimeSlot[1];
      }

      if (keywordName) params[keywordName] = keywordValue;
      console.log(params);
      const res = await getListAPI(params);
      this.list = res.list || [];
      this.total = res.total || 0;
    },

    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData);
      this.getList();
    },
    handleExport() {
      let {
        timeSlot,
        payTimeSlot,
        keywordName,
        keywordValue,
        orderStatusList,
        isRegister,
        memberType,
      } = this.searchData;
      let params = {
        page: this.currentPage,
        pageSize: this.pageSize,
        orderStatusList,
        isRegister,
        memberType,
      };
      if (timeSlot && timeSlot.length > 0) {
        params.leftOrderDate = timeSlot[0];
        params.rightOrderDate = timeSlot[1];
      }
      if (payTimeSlot && payTimeSlot.length > 0) {
        params.leftPayDate = payTimeSlot[0];
        params.rightPayDate = payTimeSlot[1];
      }

      if (keywordName) params[keywordName] = keywordValue;
      DownloadAPI(params);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;

  .el-divider--horizontal {
    margin: 0 0 20px;
  }

  .header-search {
    .el-form {
      .el-form-item {
        margin-bottom: 5px;
        // margin-right: 15px;
        .el-select {
          width: 320px;
        }
        .el-date-editor {
          .el-range-separator {
            padding: 0;
          }
          .el-range-input {
            width: 30%;
          }
        }
      }
      .keywords {
        .el-select .el-input {
          width: 100px;
        }

        .el-select {
          width: 100px;
        }
        .el-input-group {
          width: 320px;
        }
        .input-with-select {
          vertical-align: middle;
        }
        .input-with-select .el-input-group__prepend {
          background-color: #fff;
        }
      }
    }
  }
  // .header-container {
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: flex-end;
  //   .left {
  //     flex: 1;
  //     .el-form-item {
  //       margin-bottom: 0;
  //       // width: 33%;
  //       .el-select {
  //         width: 250px;
  //       }
  //       .el-date-editor {
  //         .el-range-separator {
  //           padding: 0;
  //         }
  //         // .el-range-input {
  //         //   // width: 30%;
  //         // }
  //       }
  //     }

  //     .keywords {
  //       .el-select .el-input {
  //         width: 100px;
  //       }
  //       .el-select {
  //         width: 100px;
  //       }
  //       .el-input {
  //         width: 300px;
  //       }
  //       .input-with-select {
  //         vertical-align: middle;
  //       }
  //       .input-with-select .el-input-group__prepend {
  //         background-color: #fff;
  //       }
  //     }
  //   }
  //   .right {
  //     margin-left: 20px;
  //     .open {
  //       color: #409eff;
  //       font-size: 16px;
  //       margin: 0 10px;
  //       cursor: pointer;
  //     }
  //     .updown {
  //       color: #409eff;
  //       font-size: 16px;
  //       transition: all 0.5s;
  //     }
  //     .go {
  //       transform: rotate(-180deg);
  //     }
  //   }
  // }
  .handle-btn {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}
</style>
